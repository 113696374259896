import React from "react";
import { Builder, BuilderComponent } from "@builder.io/react";

import withLayout from "../hoc/withLayout/withLayout";

const PageBuilder = () => {
  if (Builder.isPreviewing || Builder.isEditing) {
    return <BuilderComponent model="page" />;
  }

  return (
    <>
      <div className="component-default-padding min-h-[50vh]">
        <h1 className="mb-8 text-center text-2xl md:mb-14 md:text-4xl lg:text-5xl">
          Builder io preview page.
        </h1>
      </div>
    </>
  );
};

export default withLayout(PageBuilder);
